/*@import url('https://fonts.googleapis.com/css2?family=Livvic&display=swap');*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Livvic', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Password';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/password.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3366ff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3366ff;
}

/* custom backdrop  background */
.MuiDialog-root > .MuiBackdrop-root {
  background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 100%, rgba(22, 28, 36, 1) 100%);
}

.css-1m9bonx-MuiBackdrop-root-MuiDialog-backdrop {
  /* background: #999999 !important; */
}

.test input {
  color: #0b70e1;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.test .Mui-disabled {
  color: #0b70e1 !important;
  -webkit-text-fill-color: #0b70e1;
}

.test fieldset {
  border: none;
}
